import React from 'react';
import * as styles from './index.module.scss';
import { Category } from '../../const/category-data';
import { RightCircleFilled } from '@ant-design/icons';

type PropTypes = {
  vm: Category;
};

export default function ProductCardNew({vm}: PropTypes) {
  const handleClick = () => {
    location.href = `/products?id=${vm.id}`
  }

  return (
    <div className={styles.card} onClick={handleClick}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(https://heyfocus-wap.oss-cn-beijing.aliyuncs.com/category/${vm.cover})`
        }}
      ></div>
      <div className={styles.name}>{vm.text}<RightCircleFilled style={{ color: '#e20212' }} /></div>
    </div>
  );
}
