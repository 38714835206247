import React, {useEffect, useState} from 'react';
import {graphql, PageProps} from 'gatsby';
import DefaultLayout from '../layouts/default';
import HfSliderHero, {
  ViewModel as SliderHeroViewModel,
} from '../components/hf-slider-hero';
import heroImage from '../images/products-hero.jpg';
import { HomeOutlined } from '@ant-design/icons'
import {Breadcrumb, Col, Row} from 'antd';
import * as styles from './category.module.scss';
import ProductCardNew from '../components/product-card-new';
import {GAP_SIZE_BASE} from '../constants';
import HfSeo from '../components/hf-seo';
import categoryData from '../const/category-data';

const HERO_DATA: SliderHeroViewModel = [
  {
    title: '产品中心',
    subTitle: ['视觉产品'],
    imageUrl: heroImage,
  },
];

const CategoryPage = ({
  location,
  data,
}: PageProps<{directus: {product: Product[]}}>) => {
  return (
    <DefaultLayout location={location}>
      <HfSliderHero vm={HERO_DATA} />
      <div className={styles.container}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/"><HomeOutlined style={{ marginRight: 8, marginBottom: 36 }} />首页</a>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <a href="/category">产品中心</a>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row gutter={[GAP_SIZE_BASE, GAP_SIZE_BASE]}>
          {categoryData.map(product => (
            <Col sm={8} span={12}>
              <ProductCardNew vm={product} />
            </Col>
          ))}
        </Row>
      </div>
    </DefaultLayout>
  );
};

export default CategoryPage;

export const Head = () => <HfSeo title="产品中心 | 唯视智能" />;
